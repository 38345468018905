<template>
  <div class="mainform">
      <!-- 创建对账单 -->
    <div class="mainHeader">创建对账单  <span @click="back" class="back">返回></span></div>
    <div class="form">
      <div class="header">对账信息 </div>
      <statement-info   ref="statementinfo" :formData="originFormData"  class="content bascform"></statement-info>
      <div class="header">对账明细</div>
      <div class="addBtn">
        <el-button  size="small" ref="addBtn" type="primary" plain @click="addDetail">+添加明细</el-button>
      </div>
      <statement-detail ref="stadetail" :formData="originFormData" ></statement-detail>
      <div class="action" >
        <el-button class="submit" type="primary" size="medium" @click="submitBtn">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import StatementInfo from './StatementInfo.vue'
import { request } from '@/assets/js/http.js'
export default {
  components: { ...Component.components, StatementInfo },
  name: 'CreateaStatement',
  data () {
    return {
      originFormData: { data: {} },
      olddata: []
    }
  },
  watch: {
    'originFormData.data': {
      handler: function (val) {
        if (this.originFormData.data.addDetailList) {
          if (this.originFormData.data.addDetailList.length > 0) {
            for (const item in val) {
              if (item !== 'addDetailList' && item !== 'invoiceAmount' &&
              item !== 'noTaxInvoiceAmount' && item !== 'invoiceAmountIncludingTax' && item !== 'remarks') {
                if (val[item] !== this.olddata[item]) {
                  this.originFormData.data.addDetailList = []
                  this.originFormData.data.invoiceAmount = 0.00
                  this.originFormData.data.noTaxInvoiceAmount = 0.00
                  this.originFormData.data.invoiceAmountIncludingTax = 0.00
                  break
                }
              }
            }
          }
        }
        this.olddata = JSON.parse(JSON.stringify(val))
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    addDetail () { // 点击添加明细弹框
      var isPass = true
      var data = this.$refs.statementinfo.$refs.form
      data.validate((valid) => {
        if (!valid) {
          isPass = false
        }
      })
      if (!isPass) {
        this.$message({
          message: '请将信息填写完整',
          type: 'error'
        })
        return false
      }
      this.$refs.stadetail.dialogShow = true
    },
    submitBtn () {
      let ids = ''
      for (const item of this.originFormData.data.addDetailList) {
        ids = ids + item.id + ','
      }
      ids = (ids.split(',').filter(s => { return s && s.trim() })).join(',')
      this.originFormData.data.infos = []
      var wbc = false
      var dze = false
      var bchs = false
      if (this.originFormData.data.addDetailList.length <= 0) {
        wbc = true
      }
      this.originFormData.data.addDetailList.forEach(item => {
        const info = {}
        info.id = item.id
        info.number = item.currentReconciliationQuantity
        info.price = item.amountIncludingTax
        info.currentTaxAmount = item.currentTaxAmount
        info.amountNotTaxedThisTime = item.amountNotTaxedThisTime
        this.originFormData.data.infos.push(info)
        if (item.judgeEdit === '2') {
          wbc = true
        }
        // eslint-disable-next-line eqeqeq
        if (!item.currentReconciliationQuantity || item.currentReconciliationQuantity == '' || item.currentReconciliationQuantity == 0 || item.currentReconciliationQuantity == null) {
          dze = true
        }
        if (!item.amountIncludingTax || item.amountIncludingTax === '' || item.amountIncludingTax === 0 || item.amountIncludingTax == null) {
          bchs = true
        }
      })
      if (wbc) {
        this.$message({
          message: '请先保存明细项！',
          type: 'error'
        })
        return false
      }
      if (dze) {
        this.$message({
          message: '本次对账数量不可为0！',
          type: 'error'
        })
        return false
      }
      if (bchs) {
        this.$message({
          message: '本次含税金额不可为0！',
          type: 'error'
        })
        return false
      }
      request('/api/reconciliation/reconciliationInfo/saveOrder', 'post', this.originFormData.data).then((response) => {
        if (response.code === '200') {
          this.$message({
            message: '成功',
            type: 'success'
          })
          this.$router.push('/FinancialManagement/Statement')
        }
      })
    },
    back: () => {
      window.history.back()
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@/assets/css/elForm.scss';
  .back{
    float: right;
    color: #00aaff;
    font-size: 16px;
    cursor: pointer;
  }
  .addBtn{
    padding-top: 20px;
  }
</style>
